<template>
  <div class="earnest-page">
    <Head title="支付订单" />
    <Warpper top="40">
      <ul class="recharge-form">
        <li>
          <label>项目名称</label>
          <span>{{title}}</span>
        </li>
        <li>
          <label>订单金额</label>
          <span class="big red">¥{{money}}</span>
        </li>
      </ul>
      <ul class="pay-type">
        <li>
          <img class="icon" src="../../assets/images/icon_weixin.png" alt />
          <div>
            <h5>微信支付</h5>
            <p>推荐安装微信6.0.2及以上版本的用户使用</p>
          </div>
          <img class="check" src="../../assets/images/icon_right_red.png" alt />
        </li>
      </ul>
      <button @click="onSubmit" class="submit-btn">确认支付¥{{money}}</button>
    </Warpper>
  </div>
</template>

<script>
import { reqGetToPay, reqWXLogin,reqGetPayInfo } from "@/network/api";
import { isWX, getQueryString, processUrl } from "@/plugins/untils";
export default {
  name: "pay",
  data() {
    return {
      order: "",
      type: "pay_order",
      title: "",
      money: 0,
      option: null
    };
  },
  created() {
    this.order = this.$route.query.order;
    this.type = this.$route.query.type;
    if (!this.order) {
      this.$router.back();
    }
    reqGetPayInfo({
      order:this.order
    }).then(res=>{
      const { code, msg, backdata } = res;
      if (code != 0) return this.$toast({ message: msg });
      this.money = backdata.price;
      this.title = backdata.title;
    })
  },
  mounted() {},
  methods: {
    async wxLogin() {
      const res = await reqWXLogin({ backurl: window.location.href });
      await this.$store.dispatch("user/setLoginStatus", 1);
      window.location.href = res.backdata.loginurl;
    },
    jsApiCall() {
      if (this.option == null) return;
      WeixinJSBridge.invoke("getBrandWCPayRequest", this.option, res => {
        if(res.errMsg){
          this.$toast({message:res.errMsg});
          return;
        }
        if (this.type == "pay_bzj") {
          this.$router.replace({
            name: "earnest",
            query: {
              state: 1
            }
          });
        } else {
          this.$router.back();
        }
      });
    },
    onSubmit() {
      reqGetToPay({
        order: this.order
      }).then(res => {
        const { code, msg, backdata } = res;

        if (code == -2) {
          // 调用微信登录获取openid
          return this.$toast({ message: "请在微信环境中支付" })
        }

        if (code != 0) return this.$toast({ message: msg });
        this.option = JSON.parse(backdata.PayParameters);

        if (typeof window.WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              this.jsApiCall,
              false
            );
          } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", this.jsApiCall);
            document.attachEvent("onWeixinJSBridgeReady", this.jsApiCall);
          }
        } else {
          this.jsApiCall();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.earnest-page {
  width: 100%;
  min-height: 100%;
  background: #eff0ef;
}
.pay-type {
  li {
    height: 1.51rem;
    background-color: #fff;
    display: flex;
    box-sizing: border-box;
    padding: 0.4rem 0.3rem;
    align-items: center;
    .icon {
      width: 0.44rem;
      height: 0.39rem;
      margin-right: 0.26rem;
      position: relative;
      top: -0.17rem;
    }
    .check {
      width: 0.3rem;
      height: 0.3rem;
    }
    div {
      flex: 1;
      h5 {
        font-size: 0.28rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-bottom: 0.14rem;
      }
      p {
        font-size: 0.24rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
.submit-btn {
  width: 6.3rem;
  height: 0.88rem;
  background: linear-gradient(0deg, #328f96 0%, #69c4b8 100%);
  border-radius: 0.44rem;
  border: 0;
  font-size: 0.34rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin: 0.9rem auto;
  color: #ffffff;
  display: block;
  &:focus {
    outline: none;
  }
}
.recharge-form {
  background-color: #fff;
  padding: 0.2rem 0;
  box-sizing: border-box;
  margin-bottom: 0.22rem;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 0.3rem;
    box-sizing: border-box;
    label {
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    span {
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      &.big.red {
        font-size: 0.34rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff1010;
      }
    }
  }
}
</style>